import React from "react"

export default function ProjectAttributeItem(props) {
  const {type} = props
  switch(type) {
    case 'linked':
      return (
        <a href={props.link} className="attributeItem linked">
          <div className="attributeItemBody">
            <h4 className="attributeTitle">{props.title}</h4>
            <p className="attributeDetail">{props.detail}</p>
          </div>

          <div className="disclosureIcon">
            <div className="disclosureLine" />
            <div className="disclosureArrow">
              <div className="arrowTop" />
              <div className="arrowBottom" />
            </div>
          </div>
        </a>
      )
    default:
      return (
        <div className="attributeItem">
          <h4 className="attributeTitle">{props.title}</h4>
          <p className="attributeDetail">{props.detail}</p>
        </div>
      )
  }
}