import React from "react"

function ProjectActions(props) {
  const { type } = props
  switch(type) {
    case 'dual':
      return (
        <div className="projectActions dualActions">
          <a href={props.secondLink} target="_blank" rel="noreferrer" className="projectAction button large secondary">
            <p className="buttonTitle">{props.secondTitle}</p>
          </a>

          <a href={props.link} target="_blank" rel="noreferrer" className="projectAction button large primary">
            <p className="buttonTitle">{props.title}</p>
          </a>
        </div>
      )
    default:
      return (
        <div className="projectActions">
          <a href={props.link} target="_blank" rel="noreferrer" className="projectAction button large primary">
            <p className="buttonTitle">{props.title}</p>
          </a>
        </div>
      )
  }
}

export default function ProjectDetail(props) {
  return (
    <section className="mercurySection projectDetail">
      <div className="sectionContent detailContent">
        <div className="separator"></div>

        <div className="projectHeader">
          <div className="headerBody">
            <div className="projectIcon">{props.projectIcon}</div>
            
            <div className="projectHeaderText">
              <h1 className="projectName">{props.projectName}</h1>
              <p className="projectOwner">{props.projectOwner}</p>
            </div>
          </div>

          <ProjectActions
            link={props.projectButtonLink}
            title={props.projectButtonTitle}
            secondLink={props.projectSecondButtonLink}
            secondTitle={props.projectSecondButtonTitle}
            type={props.projectButtonType}
          />
        </div>

        <div className="separator"></div>

        <div className="projectDetails">
          <div className="primaryDetails">
            <div className="separator mobileOnly"></div>

            <p className="projectDescription">{props.projectDescription}</p>

            <div className="projectSolutions">
              {props.projectSolutions}
            </div>
          </div>

          <div className="secondaryDetails">
            <div className="projectRoleDetails">
              <h3 className="projectRole">{props.projectRole}</h3>
              <p className="projectDuration">{props.projectDuration}</p>
            </div>

            <p className="projectSummary">{props.projectSummary}</p>

            <div className="separator"></div>

            <div className="projectAttributes">
              {props.projectAttributes}
            </div>
          </div>

        </div>

      {/*<div className="separator"></div>*/}

      </div>
    </section>
  )
}
